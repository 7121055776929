<template>
    <div>
        <vs-row style="overflow-x: auto">
            <vs-col
                vs-type="flex"
                vs-justify="start"
                vs-align="start"
                vs-w="12"
            >
                <vs-card>
                    <vs-row style="overflow-x: auto" class="gap">
                        <vs-col
                            vs-type="flex"
                            vs-justify="start"
                            vs-align="start"
                            vs-w="3"
                        >
                            <vs-select
                                v-model="course_families_dropdown"
                                placeholder="Select Family"
                            >
                                <vs-select-item
                                    v-for="(item, index1) in course_families"
                                    :key="index1"
                                    :value="item"
                                    :text="item.name"
                                />
                            </vs-select>
                        </vs-col>
                        <!-- <vs-col
                            vs-type="flex"
                            vs-justify="start"
                            vs-align="start"
                            vs-w="7"
                        >
                            <span
                                v-if="selectedFamilyId !== 1"
                                v-show="showCources"
                                style="
                                    display: inline-flex;
                                    top: 10px;
                                    position: relative;
                                "
                                ><h6>Courses:</h6></span
                            >&nbsp;&nbsp;
                            <span
                                v-if="selectedFamilyId !== 1"
                                v-show="showCources"
                                style="
                                    display: inline-flex;
                                    top: 10px;
                                    position: relative;
                                "
                            >
                                <vs-radio
                                    v-model="selectedCourse"
                                    v-for="(course, index2) in ne_courseslist"
                                    :key="index2"
                                    vs-name="radios1"
                                    :vs-value="course"
                                    >{{ course.name }}</vs-radio
                                >
                            </span>
                        </vs-col> -->
                        <vs-col
                            vs-type="flex"
                            vs-justify="start"
                            vs-align="start"
                            vs-w="2"
                        >
                        </vs-col>
                    </vs-row>

                    <vs-row style="overflow-x: auto">
                        <vs-col
                            vs-type="flex"
                            vs-justify="start"
                            vs-align="start"
                            vs-w="12"
                        >
                            <vs-table
                                v-if="selectedFamily === 'CM'"
                                stripe
                                style="margin: auto; width: 100%"
                                :data="net_enquiry_allocations"
                            >
                                <template slot="thead">
                                    <vs-th class="fixed-side" scope="col">
                                        Cities
                                    </vs-th>
                                    <vs-th
                                        class="textCenter"
                                        scope="col"
                                        v-for="(
                                            priority, index3
                                        ) in P_prioritys"
                                        :key="index3"
                                    >
                                        <div>
                                            <p>{{ priority.name }}</p>
                                            <p v-if="priority.id === 1">
                                                {{ p1Sources.toString() }}
                                            </p>
                                            <p v-if="priority.id === 2">
                                                {{ p2Sources.toString() }}
                                            </p>
                                            <p v-if="priority.id === 3">
                                                {{ p3Sources.toString() }}
                                            </p>
                                        </div>
                                    </vs-th>
                                </template>

                                <template>
                                    <vs-tr
                                        v-for="(item, index4) in cities"
                                        :key="index4"
                                    >
                                        <vs-th class="fixed-side" scope="col">{{
                                            item.name
                                        }}</vs-th>
                                        <vs-td
                                            class="fixed-side"
                                            scope="col"
                                            v-for="(
                                                priority, index5
                                            ) in P_prioritys"
                                            :key="index5"
                                        >
                                            <div v-if="item.name">
                                                <div
                                                    style="
                                                        min-height: 100px;
                                                        min-width: 150px;
                                                        background: #ffffff;
                                                        border-radius: 5px;
                                                        border: 1px solid
                                                            darkgray;
                                                    "
                                                >
                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            {{ item.name }}
                                                            {{ priority.name }}
                                                            <a
                                                                style="
                                                                    top: 2px;
                                                                    position: relative;
                                                                "
                                                                @click="
                                                                    edit(
                                                                        item,
                                                                        priority
                                                                    )
                                                                "
                                                                ><vs-icon
                                                                    style="
                                                                        float: right;
                                                                        font-size: 22px;
                                                                        margin-bottom: 3px;
                                                                    "
                                                                    icon="post_add"
                                                                ></vs-icon
                                                            ></a>
                                                        </vs-col>
                                                    </vs-row>
                                                    <hr />

                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            <!-- item = center -->
                                                            <div
                                                                v-for="(
                                                                    CLC, index6
                                                                ) in net_enquiry_allocations"
                                                                :key="index6"
                                                            >
                                                                <div
                                                                    v-if="
                                                                        item.name ===
                                                                            CLC.ne_city &&
                                                                        priority.id ===
                                                                            parseInt(
                                                                                CLC.bucket
                                                                            ) &&
                                                                        selectedFamily ===
                                                                            CLC.ne_course
                                                                    "
                                                                >
                                                                    <small
                                                                        >{{
                                                                            CLC.full_name
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </vs-col>
                                                    </vs-row>
                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            <div></div>
                                                        </vs-col>
                                                    </vs-row>
                                                </div>
                                            </div>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <vs-table
                                v-if="
                                    selectedFamily !== 'CM' &&
                                    selectedCourse !== ''
                                "
                                stripe
                                style="margin: auto; width: 100%"
                                :data="net_enquiry_allocations"
                            >
                                <template slot="thead">
                                    <vs-th class="fixed-side" scope="col">
                                        Courses
                                    </vs-th>
                                    <vs-th
                                        class="textCenter"
                                        scope="col"
                                        v-for="(
                                            priority, index3
                                        ) in P_prioritys"
                                        :key="index3"
                                    >
                                        <div>
                                            <p>{{ priority.name }}</p>
                                            <p v-if="priority.id === 1">
                                                {{ p1Sources.toString() }}
                                            </p>
                                            <p v-if="priority.id === 2">
                                                {{ p2Sources.toString() }}
                                            </p>
                                            <p v-if="priority.id === 3">
                                                {{ p3Sources.toString() }}
                                            </p>
                                        </div>
                                    </vs-th>
                                </template>

                                <template>
                                    <vs-tr
                                        v-for="(item, index4) in ne_courseslist"
                                        :key="index4"
                                    >
                                        <vs-th class="fixed-side" scope="col">{{
                                            item.name
                                        }}</vs-th>
                                        <vs-td
                                            class="fixed-side"
                                            scope="col"
                                            v-for="(
                                                priority, index5
                                            ) in P_prioritys"
                                            :key="index5"
                                        >
                                            <div v-if="item.name">
                                                <div
                                                    style="
                                                        min-height: 100px;
                                                        min-width: 150px;
                                                        background: #ffffff;
                                                        border-radius: 5px;
                                                        border: 1px solid
                                                            darkgray;
                                                    "
                                                >
                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            {{ item.name }}
                                                            {{ priority.name }}
                                                            <a
                                                                style="
                                                                    top: 2px;
                                                                    position: relative;
                                                                "
                                                                @click="
                                                                    edit(
                                                                        item,
                                                                        priority
                                                                    )
                                                                "
                                                                ><vs-icon
                                                                    style="
                                                                        float: right;
                                                                        font-size: 22px;
                                                                        margin-bottom: 3px;
                                                                    "
                                                                    icon="post_add"
                                                                ></vs-icon
                                                            ></a>
                                                        </vs-col>
                                                    </vs-row>
                                                    <hr />

                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            <!-- item = center -->
                                                            <div
                                                                v-for="(
                                                                    CLC, index6
                                                                ) in net_enquiry_allocations"
                                                                :key="index6"
                                                            >
                                                                <div
                                                                    v-if="
                                                                        item.name ===
                                                                            CLC.ne_course &&
                                                                        priority.id ===
                                                                            parseInt(
                                                                                CLC.bucket
                                                                            )
                                                                    "
                                                                >
                                                                    <small
                                                                        >{{
                                                                            CLC.full_name
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </vs-col>
                                                    </vs-row>
                                                    <vs-row>
                                                        <vs-col vs-w="12">
                                                            <div></div>
                                                        </vs-col>
                                                    </vs-row>
                                                </div>
                                            </div>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </vs-col>
                    </vs-row>
                </vs-card>
            </vs-col>
        </vs-row>
        <vs-popup
            class="popupSize holamundo"
            :title="assignSpocTitle"
            :active.sync="neaPopup"
        >
            <vs-card>
                <vs-row>
                    <vs-col vs-w="12">
                        <vs-card>
                            <vs-row>
                                <vs-col vs-w="9">
                                    <multi-select
                                        style="z-index: 50000"
                                        :options="SpocsByFamily"
                                        :selected-options="items"
                                        placeholder="Select Spoc"
                                        @select="onSelect"
                                    >
                                    </multi-select>
                                </vs-col>
                                <vs-col vs-w="3">
                                    <vs-button @click="assignNewEntry()"
                                        >Assign Spoc</vs-button
                                    >
                                </vs-col>
                            </vs-row>
                        </vs-card>
                    </vs-col>
                </vs-row>

                <vs-row>
                    <vs-col vs-w="12">
                        <vs-table :data="popupTable">
                            <template slot="thead">
                                <vs-th> Family </vs-th>
                                <vs-th v-if="selectedFamilyId === 1">
                                    Center
                                </vs-th>
                                <vs-th> Course </vs-th>
                                <vs-th> Spoc Name </vs-th>
                                <vs-th> Priority </vs-th>
                                <vs-th
                                    style="
                                        justify-content: center;
                                        display: flex;
                                    "
                                >
                                    Action
                                </vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <vs-tr
                                    :key="indextr"
                                    v-for="(tr, indextr) in data"
                                >
                                    <vs-td>
                                        <p>{{ tr.ne_course }}</p>
                                    </vs-td>

                                    <vs-td v-if="selectedFamilyId === 1">
                                        <p>{{ tr.ne_city }}</p>
                                    </vs-td>

                                    <vs-td>
                                        <p v-if="selectedFamilyId === 1">
                                            CPA/CMA
                                        </p>
                                        <p v-else>ExEd</p>
                                    </vs-td>

                                    <vs-td>
                                        <p>{{ tr.full_name }}</p>
                                    </vs-td>

                                    <vs-td>
                                        <p>
                                            {{
                                                getPriorityById(
                                                    parseInt(tr.bucket)
                                                )
                                            }}
                                        </p>
                                    </vs-td>

                                    <vs-td>
                                        <div style="display: flex">
                                            <vs-button
                                                radius
                                                color="warning"
                                                type="gradient"
                                                icon="edit"
                                                @click="update_priority(tr)"
                                            ></vs-button
                                            >&nbsp;
                                            <vs-button
                                                radius
                                                color="danger"
                                                type="gradient"
                                                icon="delete"
                                                @click="delete_priority(tr)"
                                            ></vs-button>
                                        </div>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </vs-col>
                </vs-row>
            </vs-card>
        </vs-popup>
        <vs-popup
            class="holamundo updatePopup"
            title="Update Spoc Priority"
            :active.sync="update_entry"
        >
            <vs-card>
                <vs-table :data="updateData">
                    <template slot="thead">
                        <vs-th> Family </vs-th>
                        <vs-th v-if="selectedFamilyId === 1"> Center </vs-th>
                        <vs-th> Course </vs-th>
                        <vs-th> Spoc Name </vs-th>
                        <vs-th> Priority </vs-th>
                        <vs-th style="justify-content: center; display: flex">
                            Action
                        </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <p>{{ tr.ne_course }}</p>
                            </vs-td>

                            <vs-td v-if="selectedFamilyId === 1">
                                <p>{{ tr.ne_city }}</p>
                            </vs-td>

                            <vs-td>
                                <p v-if="selectedFamilyId === 1">CPA/CMA</p>
                                <p v-else>ExEd</p>
                            </vs-td>

                            <vs-td>
                                <p>{{ tr.full_name }}</p>
                            </vs-td>

                            <vs-td>
                                <div>
                                    <!-- <input type="radio" id="two" value="1" v-model="popupSelect">
                  <input type="radio" id="two" value="2" v-model="popupSelect">
                  <input type="radio" id="two" value="3" v-model="popupSelect"> -->
                                    <vs-radio
                                        color="dark"
                                        v-model="popupSelect"
                                        vs-value="1"
                                        >P1</vs-radio
                                    >
                                    <vs-radio
                                        color="dark"
                                        v-model="popupSelect"
                                        vs-value="2"
                                        >P2</vs-radio
                                    >
                                    <vs-radio
                                        color="dark"
                                        v-model="popupSelect"
                                        vs-value="3"
                                        >P3</vs-radio
                                    >
                                </div>
                            </vs-td>
                            <vs-td>
                                <vs-button
                                    @click="updatePriorityData(tr)"
                                    size="small"
                                    style="font-size: 12px"
                                    color="success"
                                    >update</vs-button
                                >
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-card>
        </vs-popup>
    </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import { MultiSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
export default {
    components: {
        "v-select": vSelect,
        MultiSelect,
    },
    data() {
        return {
            assignSpocTitle: "",
            searchText: "", // If value is falsy, reset searchText & searchItem
            items: [],
            lastSelectItem: {},
            SpocSelectableTitle: "",
            popupSelect: "",
            neaPopup: false,
            update_entry: false,
            course_families_dropdown: "",
            course_families: [
                { id: 1, name: "CM" },
                { id: 2, name: "Exec Ed - FINANCE" },
                { id: 3, name: "Exec Ed - NON-FINANCE" },
            ],
            selectedCourse: "",
            coursesList: [],
            showCources: false,
            courses: [],
            cities: [],
            selectedFamily: "",
            selectedFamilyId: null,
            net_enquiry_allocations: [],
            selectedCourceId: null,
            P_prioritys: [],
            SpocsByFamily: [],
            selectedSpocs: [],
            selected_city: null,
            selected_priority: null,
            selected_familyid: null,
            popupTable: [],
            loggedinId: null,
            selectedAssignSpocid: null,
            updateData: [],
            netEnquirySourceData: [],
            p1Sources: [],
            p2Sources: [],
            p3Sources: [],
            selectedTeam: "",
            ne_courseslist: [],
            selectedCourseName: "",
            radioCourse: [],
            variable_course: ""
        };
    },
    watch: {
        selectedCourse(val) {
            
            this.selectedCourseName = "";
            this.selectedCourseName = val;
            this.selectedCourceId = val.id;
            // this.getDataCourseWise();
            
            // });

            // }
        },
        course_families_dropdown(val) {
            this.getPrioritSources();
            this.courses = [];
            // console.log("family value", val);
            this.courses = val;
            this.showCources = true;
            this.selectedCourse = "";
            this.selectedFamily = val.name;
            this.selectedFamilyId = val.id;

            if (this.selectedFamilyId === 1) {
                this.getSpocsByFamily("getCM");
                this.SpocSelectableTitle = "CM Spocs";
                this.selectedCourse = this.courses.id;
                this.selectedTeam = "CM";
                this.getData();
            } else if (this.selectedFamilyId === 2) {
                this.getSpocsByFamily("getExecEdFinance");
                this.SpocSelectableTitle = "Finance Spocs";
                this.selectedCourse = [{ id: 1, name: "IIML-SF" }];
                this.selectedTeam = "ExEd";
                this.ne_courseslist = [
                    { id: 1, name: "IIML-SF" },
                    { id: 2, name: "IIML-FA" },
                ];
                // console.log("courses,", this.ne_courseslist);
                this.getData();
            } else if (this.selectedFamilyId === 3) {
                // console.log("courses,", this.courses);
                this.getSpocsByFamily("getExecEdNonFinance");
                this.SpocSelectableTitle = "Non Finance Spocs";
                this.selectedCourse = [{ id: 1, name: "IIML-BA" }];
                this.selectedTeam = "ExEd";
                this.ne_courseslist = [
                    { id: 1, name: "IIML-BA" },
                    { id: 2, name: "IIML-PM" },
                    { id: 3, name: "IIML-HR" },
                    { id: 4, name: "IITR-BF" },
                    { id: 5, name: "IITR-DB" },
                    { id: 6, name: "IITM-AA" },
                    { id: 7, name: "IITR-CC" },
                    { id: 8, name: "IIMK-FT" },
                    { id: 9, name: "IIML-AB" },
                    { id: 10, name: "IIML-SH" },
                    { id: 11, name: "IITJ-DE" },
                    { id: 12, name: "XLRI-HR" },
                    { id: 13, name: "XLRI-DM" },
                    { id: 14, name: "IIMI-BA" },
                    { id: 15, name: "XLRI-SH" },
                    { id: 16, name: "IIMI-AA" },
                ];
                // this.getData();
                this.getExecEdNonFinance();
            }
        },
    },
    mounted() {
        this.myprofile();
        // this.getData();
        this.clear();
        this.getCenters();
        this.getSpocs();
        this.getNetEnquirySourceData();
        this.getPrioritSources();
    },
    methods: {
        clear() {
            console.log("trigged", this.selectedCourse)
            // if(this.selectedCourse.name === "IITR-CC") {
            //     this.getDataCourseWise();
            // }
        },
        getDataCourseWise() {
            
            var obj = {};
            var nullObj = {};
            this.net_enquiry_allocations = [];
            // if (this.selectedFamilyId === 3) {
            this.ne_courseslist.forEach(courselist => {
                this.radioCourse.forEach((element) => {
                var variable = "";
                for (var a in element.ne_course.split(",")) {
                    variable = element.ne_course.split(",")[a];
                    obj = {
                        bucket: element.bucket,
                        first_name: element.first_name,
                        full_name: element.full_name,
                        id: element.id,
                        last_name: element.last_name,
                        ne_city: element.ne_city,
                        ne_course: variable,
                        user_tag: element.user_tag,
                    };
                    if (courselist.name === variable) {
                        if(element === "") {
                            this.net_enquiry_allocations.push(nullObj);
                        }
                        if(element !== "") {
                            this.net_enquiry_allocations.push(obj);
                        }
                        // console.log(element);
                    }
                    // console.log("trigged", this.net_enquiry_allocations)
                }
                });
            });
            
        },
        getNonExedDataCourseWise() {
            
            var obj = {};
            var nullObj = {};
            this.net_enquiry_allocations = [];
            // if (this.selectedFamilyId === 3) {
            this.ne_courseslist.forEach(courselist => {
                this.radioCourse.forEach((element) => {
                var variable = "";
                for (var a in element.sub_team.split(",")) {
                    variable = element.sub_team.split(",")[a];
                    obj = {
                        bucket: element.bucket,
                        first_name: element.first_name,
                        full_name: element.full_name,
                        id: element.id,
                        last_name: element.last_name,
                        ne_city: element.ne_city,
                        ne_course: variable,
                        user_tag: element.user_tag,
                    };
                    if (courselist.name === variable) {
                        if(element === "") {
                            this.net_enquiry_allocations.push(nullObj);
                        }
                        if(element !== "") {
                            this.net_enquiry_allocations.push(obj);
                        }
                        // console.log(element);
                    }
                    // console.log("trigged", this.net_enquiry_allocations)
                }
                });
            });
            
        },
        getPrioritSources() {
            this.p2Sources = [];
            this.p1Sources = [];
            this.p3Sources = [];
            this.netEnquirySourceData.forEach((element) => {
                if (element.bucket === 1) {
                    this.p1Sources.push(element.source);
                } else if (element.bucket === 2) {
                    this.p2Sources.push(element.source);
                } else if (element.bucket === 3) {
                    this.p3Sources.push(element.source);
                }
            });
        },
        onSelect(items, lastSelectItem) {
            // console.log("items", items);
            this.items = items;
            // this.selectedSpocs = items;
            this.lastSelectItem = lastSelectItem;
        },
        myprofile() {
            let url = `${constants.SERVER_API}getUserProfile`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("My profile", response.data.data);
                    let data = response.data.data;

                    this.loggedinId = data.id;
                    // console.log(this.loggedinId);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getSpocsByFamily(family) {
            this.SpocsByFamily = [];
            let url = `${constants.MILES_CM_BACK}` + family;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("this.AllSpocs", response);
                    this.loggedin = response.data.logged_in_user_id;
                    response.data.data.forEach((element) => {
                        if (this.selectedFamilyId === 1) {
                            this.SpocsByFamily.push({
                                value: element.id,
                                text: element.full_name + " - " + element.city,
                            });
                        } else {
                            this.SpocsByFamily.push({
                                value: element.id,
                                text:
                                    element.full_name +
                                    " - " +
                                    element.sub_team,
                            });
                        }
                    });
                    // console.log("this.SpocsByFamily", this.SpocsByFamily);
                    // console.log("this.loggedin", this.loggedin);
                    // this.extractSpocByCity(response.data.spocs);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getSpocs() {
            let url = `${constants.MILES_CM_BACK}getAllActiveUsers`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("this.AllSpocs", response);
                    // this.loggedin = response.data.logged_in_user_id;
                    this.AllSpocs = response.data;
                    // console.log("this.AllSpocs", this.AllSpocs);

                    // this.extractSpocByCity(response.data.spocs);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getExecEdNonFinance() {
            this.$vs.loading();
            this.clear();
            this.allLevels = [];
            // this.course_families = [];
            this.course_family_P_prioritys = [];
            this.net_enquiry_allocations = [];
            this.coursesList = [];
            this.radioCourse = [];
            axios
                .get(
                    `${constants.SERVER_API}getExecEdNonFinance`,
                    {
                        // params: obj,
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    console.log("netEnquiryAllocation", this.course_families_dropdown.name);
                    response.data.data.forEach((element) => {
                        // if (element.id !== 4) {
                        //   this.course_families.push({ id: 1, name: "CM" }, { id: 2, name: "ExEd" });
                        // }
                        if (this.course_families_dropdown.id !== 4) {
                            this.P_prioritys = [
                                {
                                    id: 1,
                                    name: "P1",
                                },
                                {
                                    id: 2,
                                    name: "P2",
                                },
                                {
                                    id: 3,
                                    name: "P3",
                                },
                            ];
                            this.course_family_P_prioritys = this.P_prioritys;
                        }
                        // element.courses.forEach((element) => {
                        //   this.coursesList.push(element);
                        // });
                        // element.net_enquiry_allocations.forEach((element) => {
                        //   this.net_enquiry_allocations.push(element);
                        // });
                        // console.log(this.net_enquiry_allocations);
                        if (this.selectedFamilyId === 3 || this.selectedFamilyId === 2) {
                            this.net_enquiry_allocations === [];
                            this.radioCourse.push(element);
                            this.getNonExedDataCourseWise();
                        }
                        if (
                            this.course_families_dropdown.name ===
                            element.sub_team
                        ) {
                            var obj = {};
                            var nullObj = {};
                            // if (this.selectedFamilyId === 3) {
                            this.cities.forEach((city) => {
                                var variable = element.ne_city.split(",")[a];
                                
                                for (var a in element.ne_city.split(",")) {
                                    variable = element.ne_city.split(",")[a];
                                    // console.log("variable", variable)
                                    obj = {
                                        bucket: element.bucket,
                                        first_name: element.first_name,
                                        full_name: element.full_name,
                                        id: element.id,
                                        last_name: element.last_name,
                                        ne_city: variable,
                                        ne_course: element.sub_team,
                                        user_tag: element.user_tag,
                                    };
                                    if (city.name === variable) {
                                        if(element === "") {
                                            this.net_enquiry_allocations.push(nullObj);
                                        }
                                        if(element !== "") {
                                            this.net_enquiry_allocations.push(obj);
                                        }
                                        // console.log(element);
                                    }
                                    console.log("trigged", this.net_enquiry_allocations)
                                }
                            });
                        }
                    });
                    this.P_prioritys.forEach((element) => {
                        this.allLevels.push(element);
                    });
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getData() {
            this.$vs.loading();
            this.clear();
            this.allLevels = [];
            // this.course_families = [];
            this.course_family_P_prioritys = [];
            this.net_enquiry_allocations = [];
            this.coursesList = [];
            this.radioCourse = [];
            axios
                .get(
                    `${constants.SERVER_API}getCurrentNEAllocation?team=${this.selectedTeam}&course=${this.selectedTeam}`,
                    {
                        // params: obj,
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    console.log("netEnquiryAllocation", this.course_families_dropdown.name);
                    response.data.data.forEach((element) => {
                        // if (element.id !== 4) {
                        //   this.course_families.push({ id: 1, name: "CM" }, { id: 2, name: "ExEd" });
                        // }
                        if (this.course_families_dropdown.id !== 4) {
                            this.P_prioritys = [
                                {
                                    id: 1,
                                    name: "P1",
                                },
                                {
                                    id: 2,
                                    name: "P2",
                                },
                                {
                                    id: 3,
                                    name: "P3",
                                },
                            ];
                            this.course_family_P_prioritys = this.P_prioritys;
                        }
                        // element.courses.forEach((element) => {
                        //   this.coursesList.push(element);
                        // });
                        // element.net_enquiry_allocations.forEach((element) => {
                        //   this.net_enquiry_allocations.push(element);
                        // });
                        // console.log(this.net_enquiry_allocations);
                        if (this.selectedFamilyId === 3 || this.selectedFamilyId === 2) {
                            this.net_enquiry_allocations === [];
                            this.radioCourse.push(element);
                            this.getDataCourseWise();
                        }
                        if (
                            this.course_families_dropdown.name ===
                            element.ne_course
                        ) {
                            var obj = {};
                            var nullObj = {};
                            // if (this.selectedFamilyId === 3) {
                            this.cities.forEach((city) => {
                                var variable = element.ne_city.split(",")[a];
                                
                                for (var a in element.ne_city.split(",")) {
                                    variable = element.ne_city.split(",")[a];
                                    // console.log("variable", variable)
                                    obj = {
                                        bucket: element.bucket,
                                        first_name: element.first_name,
                                        full_name: element.full_name,
                                        id: element.id,
                                        last_name: element.last_name,
                                        ne_city: variable,
                                        ne_course: element.ne_course,
                                        user_tag: element.user_tag,
                                    };
                                    if (city.name === variable) {
                                        if(element === "") {
                                            this.net_enquiry_allocations.push(nullObj);
                                        }
                                        if(element !== "") {
                                            this.net_enquiry_allocations.push(obj);
                                        }
                                        // console.log(element);
                                    }
                                    console.log("trigged", this.net_enquiry_allocations)
                                }
                            });
                        }
                    });
                    this.P_prioritys.forEach((element) => {
                        this.allLevels.push(element);
                    });
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        getCenters() {
            axios
                .get(`${constants.MILES_CM_BACK}center`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log(response);
                    this.cities = response.data.data;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        edit(Data_one, Data_two) {
            this.updateData = [];
            this.popupTable = [];
            this.selectedSpocs = [];
            this.items = [];
            console.log("Data_one", Data_one);
            console.log("Data_two", Data_two);
            if (this.selectedFamilyId === 1) {
                this.assignSpocTitle =
                    this.selectedFamily +
                    " - " +
                    this.getCenterById(Data_one.id) +
                    " - " +
                    this.getPriorityById(Data_two.id);
                this.selected_city = Data_one;
                this.selected_priority = Data_two;
            } 
            if (this.selectedFamilyId !== 1)  {
                this.selected_city = "";
                this.selected_priority = Data_two;
            }
            this.selected_familyid = this.selectedFamilyId;

            if (this.selectedFamilyId === 1) {
                this.net_enquiry_allocations.forEach((element) => {
                    if (
                        this.selected_familyid === this.selectedFamilyId &&
                        this.selected_city.name === element.ne_city &&
                        this.selected_priority.id === parseInt(element.bucket)
                    ) {
                        // console.log("element", element);
                        this.popupTable.push(element);
                    }
                    //popupTable
                });
            } else if (this.selectedFamilyId !== 1) {
                this.net_enquiry_allocations.forEach((element) => {
                    console.log("element", element);
                    var variable = "";
                    for (var a in element.ne_course.split(",")) {
                        variable = element.ne_course.split(",")[a];
                    }
                    this.variable_course = Data_one.name;
                    console.log("course ",this.variable_course);
                    if (
                        this.selected_familyid === this.selectedFamilyId &&
                        Data_one.name === variable &&
                        this.selected_priority.id === parseInt(element.bucket)
                    ) {
                        // 
                        this.popupTable.push(element);
                    }
                    //popupTable
                    this.assignSpocTitle =
                        this.selectedFamily +
                        " - " +
                        Data_one.name +
                        " - " +
                        this.getPriorityById(Data_two.id);
                });
            }
            // console.log("popupTable", this.popupTable);

            this.neaPopup = true;
        },
        getSpocById(val) {
            if (val !== null) {
                let spocs = [];
                // console.log("val", this.AllSpocs);
                // console.log("val", parseInt(val));

                let spocName = this.AllSpocs.filter((obj) => obj.id === val);
                spocs.push(spocName[0].full_name);

                return spocName[0].full_name;
            }
        },
        // getFamiliesById(fId) {
        //   let familyName = this.course_families.filter(
        //     (obj) => obj.id === parseInt(fId)
        //   );
        //   return familyName[0].name;
        // },
        getCourcesById(cId) {
            if (cId !== 0) {
                let courseName = this.coursesList.filter(
                    (obj) => obj.id === parseInt(cId)
                );
                return courseName[0].name;
            }
        },
        getPriorityById(lId) {
            // console.log("lId", this.allLevels)
            let priorityName = this.allLevels.filter(
                (obj) => obj.id === parseInt(lId)
            );
            
            return priorityName[0].name;
        },
        getCenterById(centerId) {
            let cityName = this.cities.filter(
                (obj) => obj.id === parseInt(centerId)
            );

            return cityName[0].name;
        },
        getNetEnquirySourceData() {
            let url = `${constants.MILES_CM_BACK}netEnquirySource`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // this.selectSpocCity(response);
                    // console.log("get net Enquiry data", response);
                    this.netEnquirySourceData = response.data.data;
                    ///mmm
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        assignNewEntry() {
            let obj = "";
            let spoc_ids_arr = [];
                this.items.forEach((element) => {
                    spoc_ids_arr.push(element.value);
                });
            if (this.selectedFamilyId === 1) {
                obj = {
                        team: this.selectedTeam,
                        ne_city: this.selected_city.name,
                        spoc_ids:spoc_ids_arr.toString(),
                        bucket: this.selected_priority.id.toString(),
                        ne_course: this.selectedTeam
                    };
            } else if (
                this.selectedFamilyId === 2 ||
                this.selectedFamilyId === 3
            ) { 
                obj = {
                    team: this.selectedTeam,
                    spoc_ids: spoc_ids_arr.toString(),
                    ne_city: "",
                    bucket: this.selected_priority.id.toString(),
                    ne_course: this.variable_course,
                };
            }

            if (obj.length !== 0) {
                this.assignSpocCall(obj);

                // console.log("obj", obj);
            } else {
                this.$vs.notify({
                    title: "No Spoc Selected !",
                    text: "Please select at least one Spoc to assign",
                    color: "danger",
                });
            }
        },
        assignSpocCall(obj) {
            this.selectedCourse = [{}];
            console.log("multiple spoc selection ",obj);
            axios
                .post(
                    `${constants.SERVER_API}addUserToNEAllocationBasedOnTeam`,
                    obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log("response", response);
                    if (response.data.message === "Duplicate Entry") {
                        this.$vs.notify({
                            title: "Cant Assign!",
                            text: response.data.message,
                            color: "danger",
                        });
                        this.clear();
                        this.getData();
                    } else if (
                        response.data.status === "success"
                    ) {
                        this.$vs.notify({
                            title: "success",
                            text: response.data.message,
                            color: "success",
                        });
                        this.clear();
                        this.getData();
                        // this.getDataCourseWise();
                    }
                    this.neaPopup = false;
                })
                .catch((e) => {
                    // console.log(e);
                    this.handleError(e);
                });
        },
        update_priority(tr) {
            this.updateData = [];
            // console.log("tr up", tr);
            this.popupSelect = tr.priority_id;
            this.updateData.push(tr);
            this.update_entry = true;
        },
        delete_priority(tr) {
            this.selectedCourse = [{}];
            // console.log("tr del", tr);
            let url = `${constants.MILES_CM_BACK}removeUserFromNEAllocationBasedOnTeam`;
            let obj = {
                spoc_id: tr.id
            }
            axios
                .post(url, obj,{
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("responce", response.data.message);
                    if (
                        response.data.message ===
                        "Net enquiry allocation requires at least one spoc Unable to deleted"
                    ) {
                        this.getData();
                        this.clear();
                        this.$vs.notify({
                            title: "Can't delete",
                            text: response.data.message,
                            color: "danger",
                        });
                    } else if (
                        response.data.status === "success"
                    ) {
                        this.getData();
                        this.clear();
                        // this.getDataCourseWise();
                        this.$vs.notify({
                            title: "success",
                            text: response.data.message,
                            color: "success",
                        });
                    } else {
                        this.getData();
                        this.clear();
                        this.$vs.notify({
                            title: "success",
                            text: response.data.message,
                            color: "success",
                        });
                    }

                    // this.update_entry = false;
                    this.neaPopup = false;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        updatePriorityData(tr) {
            this.selectedCourse = [{}];
            tr.priority_id = this.popupSelect;
            // console.log("tr", tr);
            let obj = {
                // family_id: tr.family_id,
                // added_by_id: tr.added_by_id,
                // spoc_id: tr.spoc_id,
                // spoc_name: tr.full_name,
                // course_id: tr.course_id,
                // city_id: tr.city_id,
                // priority_id: tr.priority_id,
                team: tr.ne_course,
                ne_city: tr.ne_city,
                spoc_ids: tr.id,
                bucket: this.popupSelect,
                ne_course: tr.ne_course,
            };
            let url = `${constants.SERVER_API}addUserToNEAllocationBasedOnTeam`;

            axios
                .post(url, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log("responce", response);
                    if (
                        response.data.message ===
                        "Net enquiry allocation requires at least one spoc Unable to Update"
                    ) {
                        this.getData();
                        this.clear();
                        this.$vs.notify({
                            title: "Can't Update",
                            text: response.data.message,
                            color: "danger",
                        });
                    } else if (
                        response.data.status === "success"
                    ) {
                        this.getData();
                        this.clear();
                        // this.getDataCourseWise();
                        this.$vs.notify({
                            title: "success",
                            text: response.data.message,
                            color: "success",
                        });
                    } else {
                        this.getData();
                        this.clear();
                        this.$vs.notify({
                            title: "success",
                            text: response.data.message,
                            color: "success",
                        });
                    }
                    this.update_entry = false;
                    this.neaPopup = false;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
    },
};
</script>

<style>
.vs-component.con-vs-popup.holamundo.updatePopup.vs-popup-primary {
    z-index: 600000;
}
.popupSize div.vs-popup {
    width: 900px;
}
.updatePopup div.vs-popup {
    width: 800px;
}
.con-select-example {
    display: flex;
    align-items: center;
    justify-content: center;
}
.con-select .vs-select {
    width: 100%;
}
@media (max-width: 550px) {
    .con-select {
        flex-direction: column;
    }
    .con-select .vs-select {
        width: 100%;
    }
}
.textCenter div.vs-table-text {
    text-align: center;
    display: flex;
    justify-content: center;
}
</style>
